import Bowser from "bowser";

export const isNextServer = () => typeof window === "undefined";
export const isNextClient = () => typeof window !== "undefined";
export const uaParse = (ua: string = "") => {
  if (!ua && isNextClient()) {
    ua = window.navigator.userAgent;
  }
  const result = Bowser.parse(ua);
  const isInJoybuyApp = ua.includes("kaappschema=openapp.jdiapp;");
  return { ...result, isInJoybuyApp };
};
