const MuiInputBase = {
  styleOverrides: {
    input: {
      "&::placeholder": {
        color: "#C2C4CC", // 修改 placeholder 的颜色为红色
        opacity: 1, // 确保在某些浏览器中 placeholder 颜色不被淡化
      },
    },
  },
};

export default MuiInputBase;
