class LoginConfig {
    constructor() {
        this.JDCloudAppId = '80001'
        this.PUB_KEY =
            'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCbNpktS9j5qbO6EB54HIYOkJdkIuyUUwgFBGRDE0+97hZDWEhK2UkzycIAomhd16sixgAULYNGGRknWhfHOMG17TUw8ZDFo7qJRAica2VBuJWd1jZylHq4TJc0xza5ZqdnjtHaF8V8ZN7A3RwnuCaTjhJt5BRBZlbe+Lag2R6RHQIDAQAB';
        this.tokenKey = 'jnosTk';
        this.refreshTokenKey = 'jnosRtk';
        this.jnosAppCategoryKey = 'jnosAppCategory';
        this.jnosProductCodeKey = 'jnosProductCode';
        this.POSTFIX_DOMAIN = typeof window !== 'undefined' ? location.hostname.substring(location.hostname.indexOf('.') + 1) : "";

        // 验证信息：本地存储的key值(Verification information: locally stored key value)
        this.StorageKeysMap = {
            verifyParams: "RETAIL_CLOUD_LOGIN_VERIFY_PARAMS",               // 账密登录时二次校验的参数(Parameters for secondary verification when logging in with account password)
            stepCode: "RETAIL_CLOUD_LOGIN_STEP_CODE",                       // 手机号登录方式验证码，实现120s内若收到验证码可重复使用来登录(Mobile phone number login method verification code, if the verification code is received within 120s, it can be reused to log in)
            urlParamsToBind: "RETAIL_CLOUD_LOGIN_URL_PARAMS_TO_BIND_PC"     // 三方账号绑定的页面参数(Page parameters bound to third-party accounts)
        }
        // 登录后用户信息存在本地
        this.loginLocalInfo = {
            USER_SUCCESS_ACCOUNT: '_lsa', // 老用户登录用户数据 account
            LOGIN_SESSIONID: '_lgs', // 存储获取的 sessionId
            LOGIN_PIN: 'pin',
            LOGIN_PHONE_CODE: 'phoneCode',
        }

        this.LoginCookieStorageType = {
            LANG_DEFAULT: "zh",
            COOKIE_NAME: "mfs_session",
            COOKIE_C_PHONE: "b2c_c_phone",
            COOKIE_C_EMAIL: "b2c_c_email",
            APP_CATEGORY_KEY: "jnosAppCategory",          // [注] 应用类型：不能改值，与SDK保持一致，SDK会用到([Note] Application type: the value cannot be changed, it is consistent with the SDK and will be used by the SDK)
            PRODUCT_CODE_KEY: "jnosProductCode",          // [注] 产品CODE：不能改值，与SDK保持一致，SDK会用到([Note] Product CODE: cannot be changed. It is consistent with the SDK and will be used by the SDK.)
            NO_LOGIN_CODE: [504, 507],
            NO_AUTH_CODE: 502,
            NO_TENANT_CODE: [10000, 10702],               // 无租户错误码(No tenant error code)
            ERROR_JD_SLIDE: [10000, "10000"],
            TENANT_CODE_KEY: "jnosTenantCode",            // 本地存储的租户Key(Locally stored tenant key)
            TENANT_CODE_HEADER: 'jnos-tenant-code',       // 租户header(Tenant header)
            APP_CODE_KEY: "jnosAppCode",                  // 本地存储的appcodeKey(Locally stored appcodeKey)
            APP_CODE_HEADER: 'jnos-app-code'              // appcode header
          };
          

    }
    LoginCookieStorageType: {
        LANG_DEFAULT: string; COOKIE_NAME: string; COOKIE_C_PHONE: string; COOKIE_C_EMAIL: string; APP_CATEGORY_KEY: string; // [注] 应用类型：不能改值，与SDK保持一致，SDK会用到([Note] Application type: the value cannot be changed, it is consistent with the SDK and will be used by the SDK)
        PRODUCT_CODE_KEY: string; // [注] 产品CODE：不能改值，与SDK保持一致，SDK会用到([Note] Product CODE: cannot be changed. It is consistent with the SDK and will be used by the SDK.)
        NO_LOGIN_CODE: number[]; NO_AUTH_CODE: number; NO_TENANT_CODE: number[]; // 无租户错误码(No tenant error code)
        ERROR_JD_SLIDE: (string | number)[]; TENANT_CODE_KEY: string; // 本地存储的租户Key(Locally stored tenant key)
        TENANT_CODE_HEADER: string; // 租户header(Tenant header)
        APP_CODE_KEY: string; // 本地存储的appcodeKey(Locally stored appcodeKey)
        APP_CODE_HEADER: string; // appcode header
    };
    loginLocalInfo: {
        USER_SUCCESS_ACCOUNT: string; // 老用户登录用户数据 account
        LOGIN_SESSIONID: string; // 存储获取的 sessionId
        LOGIN_PIN: string; LOGIN_PHONE_CODE: string;
    };
    StorageKeysMap: {
        verifyParams: string; // 账密登录时二次校验的参数(Parameters for secondary verification when logging in with account password)
        stepCode: string; // 手机号登录方式验证码，实现120s内若收到验证码可重复使用来登录(Mobile phone number login method verification code, if the verification code is received within 120s, it can be reused to log in)
        urlParamsToBind: string; // 三方账号绑定的页面参数(Page parameters bound to third-party accounts)
    };
    JDCloudAppId: string;
    PUB_KEY: string;
    tokenKey: string;
    refreshTokenKey: string;
    jnosAppCategoryKey: string;
    jnosProductCodeKey: string;
    POSTFIX_DOMAIN: string;
}

export default new LoginConfig()
