const MuiTextField = {
    styleOverrides: {
        root: {
            // 添加全局作用域
            '& .MuiInputBase-input': {
                // 处理所有自动填充状态
                '&:-webkit-autofill': {
                    WebkitBoxShadow: 'inherit',
                    WebkitTextFillColor: 'inherit',
                    caretColor: "#fff"
                },
                // 获取焦点时光标颜色
                '&:focus': {
                    caretColor: "inherit"  // 确保获取焦点时光标颜色为默认色
                }
            }
        }
    }
};

export default MuiTextField;
