/* 端类型枚举(自定义枚举)(End type enumeration (custom enumeration)) */
export const ClientEnum = {
  B : "seller",               // B端
  C : "user",                 // C端
  E : "oper",                 // E端：给到业务用(For business use)
  O : "oauth",                // 自定义使用的端：授权登录（用作宙斯授权登录）    [注] KSA中东未使用，保留此处配置(Customized terminal: authorized login (used as Zeus authorized login) [Note] KSA Middle East is not used, keep the configuration here)
  U : "union",                // 自定义使用的端：联合登录（用作三方账号联登录）  [注] KSA中东未使用，保留此处配置(Customized terminal: joint login (used as a three-party account joint login) [Note] KSA Middle East is not used, keep the configuration here)
  EJ : "jnos",                // 自定义使用的端: 给到JNOS基础系统用(Customized terminal: for use in JNOS basic system)
  BO : "open",                // 自定义使用的端：给到开放平台使用(Customized terminal: for use on open platforms)
}
/* 应用类型(自定义枚举)(Application type (custom enumeration)) */
export const AppCategoryEnum = {
  C : "C",                    // C端的应用类型（目前未使用，目前C端传递的值是B，见下一行）(The application type of the C side (currently not used, the current value passed by the C side is B, see the next line))
  B : "B",                    // B端的应用类型（目前C端和B端都传值B）(The application type of the B side (currently both the C side and the B side pass the value B))
  E : "E",                    // E端应用类型(E-side application type)
}
/* 产品类型(product type) */
export const ProductCodeEnum = {
  B2B : 'b2b',
  B2C : 'b2c',
  B2P : 'b2p'
}
/* 三方账号绑定方式(Three-party account binding method) */
export const MyBindWayEnum = {
  REDIRECT_URI : "redirect_uri",
  POP_UP : "pop_up"
}
/* 判断登录用户是新\用户 */
export const LoginConfigEnum = {
  NEW_USER: 'new', // 新用户
  OLD_USER: 'old', // 老用户
  PHONE_LOGIN: 'phone', // 手机登录
  EMAIL_LOGIN: 'email'
}

/* 用户类型(账号类型)(User type (account type)) */
export const UserTypeEnum = {
  ACCOUNT_EXCLUSIVE : 0,                      // 专属账号：子账号(Exclusive account: sub-account)
  ACCOUNT_RETAIL_CLOUD_MAIN : 1,              // 主账号(零售云账号)：主账号(Main account (retail cloud account): Main account)
  ACCOUNT_RETAIL_CLOUD_INVITED : 2,           // 受邀请的零售云账号：子账号(Invited retail cloud account: sub-account)
  ACCOUNT_OPER : 3                            // 运营端账号：运营账号(Operation account: Operation account)
}
/* 短信验证码场景枚举(SMS verification code scenario enumeration) */
export const VerifyCodeSceneEnum = {
  REGISTER : 1,                               // 账号注册或平台账号注册(Account registration or platform account registration)
  LOGIN : 2,                                  // 登录(Log in)
  BIND_MOBILE_OR_EMAIL : 3,                   // 绑定手机号(Bind mobile phone number)
  MODIFY_MOBILE_OLD : 4,                      // 修改手机号(原手机号发送验证码)(Modify mobile phone number (original mobile phone number will send verification code))
  MODIFY_MOBILE_NEW : 5,                      // 修改手机号(新手机号发送验证码)(Modify mobile phone number (new mobile phone number will send verification code))
  MODIFY_PASSWORD : 6,                        // 修改密码(change Password)
  RETRIEVE_PASSWORD : 7,                      // 找回密码(Retrieve password)
  MODIFY_EMAIL : 8,                           // 修改邮箱(原手机号发送验证码)(Modify email (original mobile phone number to send verification code))
  PERSON_REAL_NAME : 9,                       // 个人实名认证(Personal real-name authentication)
  ONE_STEP_MODIFY_MOBILE_NEW : 10,            // 一步修改手机号(新手机号发送验证码)(Modify mobile phone number in one step (new mobile phone number will send verification code))
  MFA_SECOND_CONFIRM : 10                     // MFA二验(MFA secondary verification)
}
export const LoginStatusEnum = {
  LOGIN : 1,                                  // 直接登录(Direct Login)
  CREATE_ORG : 2,                             // 创建组织: 跳选择组织页面(Create an organization: Jump to the select organization page)
  CHOOSE_ORG : 3                              // 选择组织: 跳选择组织页面(Select organization: jump to select organization page)
}
/* 登录类型(Login type) */
export const LoginTypeEnum = {
  ACCOUNT : 1,                                // 账号登录(Account login)
  MOBILE : 2,                                 // 手机号登录(Mobile phone number login)
  EMIAL : 3,                                  // 邮箱登录(Email Login)
  JD: 4,                               
  WECHAT:5,                                   // 微信登录(WeChat login)
  WX_MINI_PROGRAM : 6,                        // 微信小程序(WeChat applet)
  APPLE : 23,                                 // Apple账号登录(Apple account login)
  FACEBOOK : 24,                              // Facebook账号登录(Facebook account login)
  GOOGLE : 25                                 // Google账号登录(Google account login)
}
/* 组织状态(organizational status) */
export const AccountSserstatusEnum = {
  DEACTIVATED : 3                             // 已停用(terminated)
}
/* 组织类型(Organization type) */
export const AccountTypeEnum = {
  ORDINARY : 1,                               // 普通组织(Ordinary organization)
  OPERATIONAL : 2                             // 运营组织(operating organization)
}
/* 组织状态(organizational status) */
export const AccountStatusEnum = {
  NORMAL : 1,                                 // 有效/正常(valid/normal)
  DISABLED : 3                                // 禁用(Disable)
}
/* 组织中用户状态(User status in the organization) */
export const AccountUserStatusEnum = {
  NORMAL : 1,                                 // 有效/正常(valid/normal)
  NOT_ACTIVE :  2,                            // 未激活(inactivated)
  DISABLED : 3                                // 禁用(Disable)
}
/* 商户类型(Merchant type) */
export const BizTypeEnum = {
  POPB : 'popb',
  POPC : 'popc',
}
/* 商户页面展示类型(Merchant page display type) */
export const StoreTypeShowCodeEnum = {
  B : 'B',
  C : 'C',
}
/* 商户类型(Merchant type) */
export const AccountLevelEnum = {
  BUSINESS : 1,                               // 商家(Merchant)
  STORE : 2,                                  // 店铺(shop)
}
/* 校验状态(手机号/邮箱)(Verification status (mobile phone number/email)) */
export const VerifiedEnum = {
  NO : 0,                                     // 未校验(Not verified)
  YES : 1                                     // 校验(Verified)
}
export const PasswordStatusEnum = {
  SET : 0,                                    // 已设置(Has been set)
  NOT_SET : 1                                 // 未设置(not set)
}
export const InviteStatusEnum = {
  SUCCESS : 1,
  INVITING : 2,
  CANCEL : 3,
  EXPIRED : 4
}
/* 图形校验SDK国际化语言枚举值(Graphics verification SDK international language enumeration value) */
export const SlideLanguageEnum = {
  zh_CN : 1,                                  // 中文-简体(Chinese-Simplified)
  zh_TW : 2,                                  // 中文-繁体(traditional Chinese)
  en_US : 3,                                  // 英语-美国(English-United States)
  th_TH : 4,                                  // 泰语(Thai)
  ru_RU : 5,                                  // 俄语(Russian)
  es_ES : 6,                                  // 西班牙语-国际(Spanish - International)
  id_ID : 7,                                  // 印度尼西亚语(Indonesian)
  ko_KR : 8,                                  // 朝鲜语(Korean language)
  ja_JP : 9,                                  // 日语(Japanese)
  nl_NL : 10,                                 // 荷兰语-荷兰(Dutch - Netherlands)
  ar_SA : 11                                  // 阿拉伯语-沙特阿拉伯(Arabic - Saudi Arabia)
}
/* 国际化语言枚举(Internationalized language enumeration) */
export const LanguageEnum = {
  zh_CN : "zh_CN",                            // 中文(Chinese)
  en_US : "en_US",                            // 英文(English)
  ar_SA : "ar_SA"                             // 阿拉伯语-沙特阿拉伯(Arabic - Saudi Arabia)
}
/* 右布局语言列表(Right layout language list) */
export const RtlLayoutLanguageList = [LanguageEnum.ar_SA];






