"use client";
import { createTheme } from "@mui/material";
import MuiAlert from "@/customThemes/components/MuiAlert";
import { MuiDialog, MuiDialogTitle, MuiDialogActions, MuiDialogContent, MuiDialogContentText } from "@/customThemes/components/MuiDialog";
import MuiButton from "@/customThemes/components/MuiButton";
import MuiCheckbox from "@/customThemes/components/MuiCheckbox";
import MuiRadio from "@/customThemes/components/MuiRadio";
import MuiInputBase from "@/customThemes/components/MuiInputBase";
import MuiTextField from "@/customThemes/components/MuiTextField";

const theme = createTheme({
  cssVariables: true,
  typography: {
    // fontFamily: 'var(--font-roboto)'
    fontFamily: "var(--font-family)",
  },
  palette: {
    mode: "light",
    primary: {
      main: "#CC0C1C",
    },
    secondary: {
      main: "#FF475D",
    },
  },
  components: {
    MuiButton,
    MuiAlert,
    MuiDialog,
    MuiDialogTitle,
    MuiDialogContent,
    MuiDialogContentText,
    MuiDialogActions,
    MuiRadio,
    MuiCheckbox,
    MuiInputBase,
    MuiTextField
  },
});

export default theme;
