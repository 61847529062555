import SuccessIcon from "@/assets/icons/SuccessIcon.svg";
import InfoIcon from "@/assets/icons/InfoIcon.svg";
import WarningIcon from "@/assets/icons/WarningIcon.svg";
import ErrorIcon from "@/assets/icons/ErrorIcon.svg";

const MuiAlert = {
  defaultProps: {
    iconMapping: {
      success: <SuccessIcon width={20} style={{ marginTop: "2px" }} />,
      info: <InfoIcon width={20} style={{ marginTop: "2px" }} />,
      warning: <WarningIcon width={20} style={{ marginTop: "2px" }} />,
      error: <ErrorIcon width={20} style={{ marginTop: "2px" }} />,
    },
  },
  styleOverrides: {
    root: ({ ownerState }) => ({
      fontSize: "14px",
      padding: "0 10px",
      ...(ownerState.severity === "success" && {
        backgroundColor: "#EAF8EC",
        color: "#269636",
        border: "1px solid #ACE4B4",
      }),
      ...(ownerState.severity === "info" && {
        backgroundColor: "#EAF5FD",
        color: "#207FBB",
        border: "1px solid #A9D9F7",
      }),
      ...(ownerState.severity === "warning" && {
        backgroundColor: "#FFF8EA",
        color: "#C99227",
        border: "1px solid #FDE2AD",
      }),
      ...(ownerState.severity === "error" && {
        backgroundColor: "#FFE7E9",
        color: "#CC0C1C",
        border: "1px solid #FF9FA7",
      }),
    }),
    icon: {
      marginRight: "2px",
    },
  },
};

export default MuiAlert;
