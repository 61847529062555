const MuiButton = {
  styleOverrides: {
    root: {
      textTransform: "none",
      "&.Mui-disabled": {
        backgroundColor: "#FF9FA7",
        color: "#F5F6FA",
      },
      "&.Mui-disabled::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        cursor: "not-allowed",
        pointerEvents: "auto",
      },
    },
    outlined: {
      color: "#1A1A1A",
      border: "1px solid #E0E1E5",
      "&:hover": {
        border: "1px solid #CC0C1C",
      },
    },
  },
};

export default MuiButton;
