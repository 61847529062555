"use client";
import cookieUtil from "@/utils/cookieUtils";
import { useEffect } from "react";
import { fingerPrintCookieKey } from "@/utils/fingerprint";

const FingerPrint = () => {
  useEffect(() => {
    try {
      const POSTFIX_DOMAIN = location.hostname.substring(location.hostname.indexOf(".") + 1);
      getJsToken(function (res) {
        const { jsToken } = res;
        cookieUtil.setCookie(fingerPrintCookieKey, jsToken, 365, POSTFIX_DOMAIN);
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  }, []);
  return <></>;
};

export default FingerPrint;
