const MuiDialog = {
  styleOverrides: {
    paper: {
      backgroundColor: "#FFF",
      borderRadius: "12px",
      boxShadow: "box-shadow: 0px 8px 56px -2px #0000000A, 0px 20px 35px -6px #0000000F;",
      minWidth: "520px",
    },
  },
};

const MuiDialogTitle = {
  styleOverrides: {
    root: {
      fontFamily: "SF Pro Display",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "20px",
      textAlign: "left",
      color: "#1A1A1A",
    },
  },
};

const MuiDialogContent = {
  styleOverrides: {
    root: {
      color: "#1A1A1A",
    },
  },
};

const MuiDialogContentText = {
  styleOverrides: {
    root: {
      color: "#1A1A1A", // 修正 DialogContentText 的颜色
    },
  },
};

const MuiDialogActions = {
  styleOverrides: {
    root: {
      padding: "24px",
      paddingTop: "0px",
      "& > :not(style) + :not(style)": {
        marginLeft: "12px",
      },
    },
  },
};

export { MuiDialog, MuiDialogTitle, MuiDialogContent, MuiDialogContentText, MuiDialogActions };
