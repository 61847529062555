"use client";
import React, { useEffect, useState } from "react";
import { Button, DialogTitle } from "@mui/material";
import { usePathname } from "next/navigation";
import { UKDialog } from "@/common-components-src/js/server";
import { JDILogin } from "@/lib/jdiClientUtils";
import cookieUtil from "@/utils/cookieUtils";
import { fetchVersionInfo, fetchSettingsConsent } from "@/apis/help/help.api";
import RootData from "./constants";
import styles from "./index.module.scss";

const PrivacyPolicyUpdate = () => {
  const [isShowUpdate, setIsShowUpdate] = useState<boolean>(false);
  const pathname = usePathname();
  const { privacyRouteWhitelist } = RootData;
  useEffect(() => {
    const isInRouteWhitelist = privacyRouteWhitelist.some((item) => pathname.includes(item));
    if(!isInRouteWhitelist) {
      const isPrivacyPolicyUpdate = cookieUtil.getSessionStorage("isPrivacyPolicyUpdate") || 1;
      // 如果缓存里的隐私协议发生变更会重复请求接口提示用户操作
      if (Number(isPrivacyPolicyUpdate)) {        
        getVersionInfo();
      }
    }
    openLogin()
  }, []);

  const getVersionInfo = async () => {
    const { code, data } = await fetchVersionInfo();
    if (code === "200") {
      setIsShowUpdate(data?.isPrivacyPolicyUpdate);
      cookieUtil.setSessionStorage("isPrivacyPolicyUpdate", Number(data?.isPrivacyPolicyUpdate).toString());
    }
  };

  // 退出登录态以后帮用户打开登录弹窗并且清楚sessionStorage
  const openLogin = async () => {
    const openLoginDialog = cookieUtil.getSessionStorage("openLoginDialog");
    if(openLoginDialog) {
      await JDILogin.getInstance().openLoginDialog();
      cookieUtil.clearSessionStorage("openLoginDialog");
    }
  }
  
  const handleAgree = async () => {
    await fetchSettingsConsent();
    setIsShowUpdate(false);
  };

  // 用户点击Cancel退出登录态并跳转至登录页面
  const handleCancel = async () => {
    setIsShowUpdate(false);
    await JDILogin.getInstance().logOut();
    cookieUtil.setSessionStorage("openLoginDialog", "1")
    //
  };

  return (
    <UKDialog
      open={isShowUpdate}
      showCloseBtn={false}
      sx={{
        "&": {
          zIndex: 2003,
        },
        "& .MuiDialog-paper": {
          width: "480px",
          maxWidth: "none",
          borderRadius: "12px",
          zIndex: 2004,
        },
        "& .css-1cennmq-MuiBackdrop-root-MuiDialog-backdrop": {
          zIndex: 2003,
        },
      }}
    >
         <DialogTitle
          sx={{
            height: "20px",
            fontSize: "16px",
            lineHeight: "20px",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            padding: "0 24px",
            margin: "20px 0",
            color: "#1A1A1A"
          }}
        >
          Privacy Policy Update
        </DialogTitle>
      <div className={styles.updateWrapper}>
        <div className={styles.contentWrapper}>
          We have updated our Privacy Policy to better protect your personal information and comply with relevant laws and regulations. Click to view the
          detailed <a className={styles.link} href={`/help/privacy-policy`} target="_self">
            privacy policy.
          </a>
        </div>
        <div className={styles.btnWrapper}>
        <Button
            size="small"
            variant="contained"
            disableElevation
            sx={{
              color: "#1A1A1A",
              height: "32px",
              backgroundColor: "#FFF",
              textTransform: "none",
              border: "1px solid #E0E1E5",
              lineHeight: "18px",
              fontSize: "14px",
              padding: "7px 19px",
              borderRadius: "4px",
            }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            disableElevation
            sx={{
              color: "#FFF",
              height: "32px",
              backgroundColor: "#CC0C1C",
              textTransform: "none",
              border: "none",
              lineHeight: "18px",
              fontSize: "14px",
              padding: "7px 16px",
              borderRadius: "4px",
              marginLeft: "12px"
            }}
            onClick={handleAgree}
          >
            Agree and Continue
          </Button>
        </div>
      </div>
    </UKDialog>
  );
};

export default PrivacyPolicyUpdate;
